<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>印章库管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">
      <!--数据表格筛选-->
      <el-form ref="form" :model="searchFrom" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input placeholder="请输入印章名称" v-model="searchFrom.keyword" class="input-with-select">
              <el-button slot="append" type="warning" icon="el-icon-search" @click="loadSeals()">
              </el-button>
            </el-input>
          </el-col>
          <el-col :span="17" class="text-align-r">
            <el-button-group>
              <template>
                <el-button type="primary" @click="dialogIncreaseVisible = true">新增</el-button>
                <el-button type="primary" @click="dialogEditVisible = true">编辑</el-button>
                <el-button type="primary" @click="deleteBtn(null)" :index="this.index">删除</el-button>
              </template>
            </el-button-group>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <div class="picbox" ref="sealData" v-loading="loading" prop="tableData">
        <a v-for="(item, index) in tableData" :key='index' :class="{changeStyle:changeSelectStyle == index}"
           @click="selectSealItem(index,item)">
          <img :src="item.PhotoPath" :alt="item.Name"/>
        </a>
      </div>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination class="pagination" @size-change="changePageSize" @current-change="changeCurrentPage"
                     background layout="total, sizes, prev, pager, next" :current-page="pageInfo.currentPage"
                     :page-size="pageInfo.pageSize" :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
      <el-dialog class="increaseForm" title="新增印章" append-to-body
                 :visible.sync="dialogIncreaseVisible">
        <seal-increase :dialogIncreaseVisible="dialogIncreaseVisible" :tableData="this.tableData" :index="this.index"
                       @increaseChanged="increaseDataChange($event)"/>
      </el-dialog>
      <el-dialog class="increaseForm" v-if="dialogEditVisible" title="编辑印章" append-to-body
                 :visible.sync="dialogEditVisible" :close-on-click-modal="false">
        <seal-edit :dialogEditVisible="dialogEditVisible" :tableData="this.tableData" :index="this.index"
                   @editChanged="editDataChange($event)"/>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import sealEdit from './components/edit'
import sealIncrease from './components/increase'
import {
  upload
} from '@/api/common'
import {
  addSeal,
  editSeal,
  deleteSeal,
  querySeal
} from '@/api/seal'

export default {
  name: 'Seal',
  components: {
    sealEdit,
    sealIncrease
  },
  props: {},
  data () {
    return {
      changeSelectStyle: '',
      selectedSeal: null, // 选中的印章
      // isShow: false,
      dialogIncreaseVisible: false,
      dialogEditVisible: false,
      dialogImageUrl: '',
      index: 1,
      disabled: false,
      sealName: '',
      dialogDeleteVisible: false,
      input1: '',
      loading: true, // 表单数据加载中 loading
      // 查询条件
      searchFrom: {
        page: 1,
        pageSize: 10,
        keyword: ''
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      // 绑定数据
      tableData: [],
      SealId: 1,
      PhotoPath: '',
      Name: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadSeals()
  },
  mounted () {
  },
  methods: {
    // 选中样式切换
    selectSealItem: function (index, item) {
      this.changeSelectStyle = index
      this.selectedSeal = item
      this.index = index
    },
    // 加载默认印章数据
    loadSeals () {
      // 展示加载中 loading
      this.loading = true
      querySeal(this.searchFrom).then(res => {
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        this.tableData = res.data
        // 关闭加载中 loading
        this.loading = false
      })
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.searchFrom.pageSize = pageSize // 改变每页显示的条数
      this.searchFrom.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadSeals() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.searchFrom.page = page // 改变默认的页数
      this.loadSeals()
    },
    loadArticles (page = 1) {
    },
    handleDownload (file) {
      console.log(file)
    },
    increaseDataChange (params) {
      this.dialogIncreaseVisible = params
      this.loadSeals(this.page)
    },
    editDataChange (params) {
      this.loadSeals(this.page)
      this.dialogEditVisible = params
    },
    deleteBtn (row) {
      let ids = []
      if (row === null) {
        const tableData = this.tableData
        if (tableData.length === 0) {
          this.$message({
            message: '请选择一条数据进行删除！',
            type: 'warning'
          })
          return
        }
        ids = tableData.map((item) => item.SealId)
      } else {
        ids.push(this.index.SealId)
      }
      this.$confirm('此操作将永久删除该印章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSeal(ids[this.index]).then((res) => {
          // 删除成功，更新当前页的文章数据列表
          this.loadSeals(this.page)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scoped>
.filter-card {
  margin-bottom: 30px;
}

.picbox .changeStyle {
  border: #d23a1d 2px dotted !important;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}

.picbox a:hover {
  border: #d23a1d 2px dotted !important;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
}

.picbox a {
  margin: 10px;
  display: inline-block;
  width: 18%;
  box-sizing: border-box;
  overflow: hidden;
}

@media (min-width: 1920px) {
  .picbox a {
    margin: 10px;
    display: inline-block;
    width: 15.3%;
    box-sizing: border-box;
    overflow: hidden;
  }

}

.picbox img {
  width: 100%;
}
</style>
