<template>
  <el-form ref="editForm" :model="editForm" label-width="80px" size="mini" v-loading="loading" element-loading-text="保存中...请稍等">
    <!-- <el-form-item label="模板编号" prop="id">
      <el-input v-model="editForm.id"></el-input>
    </el-form-item> -->
    <el-form-item label="模板名称" prop="name">
      <el-input v-model="editForm.name"></el-input>
    </el-form-item>
    <el-form-item label="文档分类">
      <el-select class="select-type" v-model="editForm.docType" placeholder="请选择文档分类">
        <el-option v-for="item in docData" :key="item.Name" :label="item.Name" :value="item.Name"></el-option>
        <!-- <el-option label="询问通知书模版" value="询问通知书模版"></el-option>
        <el-option label="权利义务告知书模板" value="权利义务告知书模板"></el-option>
        <el-option label="询问笔录模板" value="询问笔录模板"></el-option>
        <el-option label="辨认说明模板" value="辨认说明模板"></el-option>
        <el-option label="调取证据通知书模板" value="调取证据通知书模板"></el-option>
        <el-option label="调取证据材料清单" value="调取证据材料清单"></el-option>
        <el-option label="讯问笔录模板" value="讯问笔录模板"></el-option>
        <el-option label="辨认笔录模板" value="辨认笔录模板"></el-option> -->
      </el-select>
    </el-form-item>

    <!-- <el-form-item label="文件模板" prop="templateContent" style="margin-bottom: 30px;">
      <el-tiptap
        v-model="editForm.templateContent"
        :extensions="extensions"
        height="350"
        lang="zh"
        placeholder="请输入文件模板"
      ></el-tiptap>
      <div class="custom-mount"></div>
    </el-form-item> -->
    <iframe ref="iframe" id="wordOnlineIframe" @blur="wordOnlineBlur" :src="wordOnlineUrl" frameborder="0" class="pc iframe" scrolling="auto" style="width: 100%;height: 68vh"></iframe>
    <el-button type="primary" @click="openScreen">全屏显示</el-button>
    <!-- <el-button type="primary" @click="ifFullScreen = false" v-if="ifFullScreen">关闭全屏</el-button> -->
    <el-button type="primary" @click="templateEditSaveBtn">提交</el-button>
  </el-form>
</template>

<script>

import {
  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  HorizontalRule,
  Fullscreen,
  Preview,
  CodeBlock,
  TextColor
} from 'element-tiptap'
import 'element-tiptap/lib/index.css'
import { getTemplateId,editTemplate } from '@/api/template'
import { getWordOnlineUrl } from '@/api/wordOnline'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'edit',
  components: {
    'el-tiptap': ElementTiptap
  },
  props: {
    dialogEditFormVisible: {
      type: Boolean,
      default: true // 默认值
    },
    index: {
      type: Number,
      default: 123
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      editForm: {
        name: '',
        docType: '',
        // templateContent: '',
        id: 0,
        fileId:''
      },
      docData:[],
      wordOnlineUrl:'', //在线word读取地址
      // ifFullScreen:false,
      // extensions: [
      //   new Doc(),
      //   new Text(),
      //   new Paragraph(),
      //   new Heading({ level: 3 }),
      //   new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
      //   new Underline(), // 下划线
      //   new Italic(), // 斜体
      //   new Strike(), // 删除线
      //   new HorizontalRule(), // 华丽的分割线
      //   new ListItem(),
      //   new BulletList(), // 无序列表
      //   new OrderedList(), // 有序列表
      //   new TodoItem(),
      //   new TodoList(),
      //   new Fullscreen(),
      //   new Preview(),
      //   new CodeBlock(),
      //   new TextColor()
      // ]
    }
  },
  computed: {},
  watch:{
  },
  created () {
  },
  mounted () {
    this.loadEditTemplate()
    getDictionaryByTypeCode({code:'DOCTYPE',status:0}).then(res => {
        this.docData = res
    })
  },
  methods: {
    loadEditTemplate () {
      const ids = this.tableData.map((item) => item.TemplateId)
      getTemplateId(ids[this.index]).then(res => {
        console.log(res)
        this.editForm.id = res.TemplateId
        this.editForm.name = res.Name
        this.editForm.docType = res.DocType
        this.editForm.templateContent = res.TemplateContent
        this.editForm.fileId = res.FileId
        getWordOnlineUrl(this.editForm.fileId,true).then((res) => {
          if (res) {
            // let url = res.Url.replace("oos","fw").replace("/we/","/wosedit/").replace("/wv/","/wosview/")
            this.wordOnlineUrl = res.Url
          }
        })
      })
    },
    templateEditSaveBtn () {
      let _this = this
      _this.loading = true
      
      setTimeout(function(){
        _this.loading = false
        console.log(editTemplate)
        _this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        const ids = _this.tableData.map((item) => item.TemplateId)
        const editId = ids[_this.index]
        if (editId) {
          editTemplate(editId, _this.editForm).then(res => {
            _this.editForm = res.data
            _this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
          _this.$emit('editChanged', false)
          _this.loadEditTemplate()
        }
      })
      },5000)

    },
    closeBtn () {
      this.$emit('editChanged', false)
    },
    openScreen(){
      var el = document.getElementById("wordOnlineIframe");
          ( el.requestFullscreen
      || el.webkitRequestFullScreen
      || el.mozRequestFullScreen 
      || el.msRequestFullscreen).call(el)
    },
    wordOnlineBlur(){
      alert(123123)
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
.custom-mount { width: 100%; height: 100%; }
</style>
