<template>
  <el-form ref="addForm" :model="increaseForm" label-width="80px" size="mini" :rules="formRules" v-if="showincreaseForm">
    <el-form-item label="案件编号" prop="seqNo">
      <el-input v-model="increaseForm.seqNo"></el-input>
    </el-form-item>
    <el-form-item label="案件名称" prop="name">
      <el-input v-model="increaseForm.name"></el-input>
    </el-form-item>
    <el-form-item label="案件类型" prop="caseType">
      <el-select class="select-type" placeholder="案件类型" v-model="increaseForm.caseType">
        <el-option v-for="item in caseData" :key="item.Name" :label="item.Name" :value="item.Name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="案件简述" prop="resume">
      <el-input type="textarea" placeholder="请输入内容" v-model="increaseForm.resume" maxlength="30" show-word-limit>
      </el-input>
    </el-form-item>
    <el-form-item label="案件详述" prop="detail" style="margin-bottom: 30px;">
      <el-tiptap
        v-model="increaseForm.detail"
        :extensions="extensions"
        height="350"
        lang="zh"
        placeholder="请输入案件内容"
      ></el-tiptap>
    </el-form-item>
    <el-button type="primary" @click="increaseSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import {
  // ElementTiptap,
  // Doc,
  // Text,
  // Paragraph,
  // Heading,
  // Bold,
  // Underline,
  // Italic,
  // Strike,
  // ListItem,
  // BulletList,
  // OrderedList,
  // TodoItem,
  // TodoList,
  // HorizontalRule,
  // Fullscreen,
  // Preview,
  // CodeBlock,
  // TextColor

  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Link,
  Image,
  CodeBlock,
  Blockquote,
  TodoItem,
  TodoList,
  TextAlign,
  FontSize,
  FontType,
  Fullscreen,
  TextHighlight,
  TextColor,
  FormatClear,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  History,
  TrailingNode,
  HardBreak,
  HorizontalRule,
  LineHeight,
  Indent,

  Preview,
} from 'element-tiptap'
import 'element-tiptap/lib/index.css'
import { getCase,addCase } from '@/api/case'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'increase',
  components: {
    'el-tiptap': ElementTiptap
  },
  props: ['dialogFormVisible'],
  data () {
    return {
      increaseForm: {
        seqNo: '',
        name: '',
        caseType: '',
        resume: '',
        detail: ''
      },
      extensions: [
        // new Doc(),
        // new Text(),
        // new Paragraph(),
        // new Heading({ level: 3 }),
        // new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
        // new Underline(), // 下划线
        // new Italic(), // 斜体
        // new Strike(), // 删除线
        // new HorizontalRule(), // 华丽的分割线
        // new ListItem(),
        // new BulletList(), // 无序列表
        // new OrderedList(), // 有序列表
        // new TodoItem(),
        // new TodoList(),
        // new Fullscreen(),
        // new Preview(),
        // new CodeBlock(),
        // new TextColor()

        new Doc(), // 必须项
        new Text(), // 必须项
        new Paragraph(), // 必须项
        new Heading({ level: 6 }), // 标题
        new Bold({ bubble: true }), // 加粗 bubble: true 在气泡菜单中渲染菜单按钮
        new Underline({ bubble: true, menubar: false }), // 下划线 bubble: true, menubar: false 在气泡菜单而不在菜单栏中渲染菜单按钮
        new Italic({ bubble: true }), // 斜体
        new Strike({ bubble: true }), // 删除线
        new ListItem(), // 使用列表必须项
        new BulletList({ bubble: true }), // 无序列表
        new OrderedList({ bubble: true }), // 有序列表
        new Link(), // 链接
        new Image(), // 图片
        new CodeBlock({ bubble: true }), // 代码块
        new Blockquote(), // 引用
        new TodoItem(), // 任务列表必须项
        new TodoList(), // 任务列表
        new TextAlign({ bubble: true }), // 文本对齐方式
        new FontSize({ bubble: true }), // 字号
        new FontType({ bubble: true }), // 字体
        new Fullscreen(), // 全屏
        new TextHighlight({ bubble: true }), // 文本高亮
        new TextColor({ bubble: true }), // 文本颜色
        new FormatClear({ bubble: true }), // 清除格式
        new Table({ resizable: true }), // 表格
        new TableHeader(), // 表格必须项
        new TableCell(), // 表格必须项
        new TableRow(), // 表格必须项
        new History(), // 撤销
        new TrailingNode(), // 重做
        new HardBreak(), // 分割线
        new HorizontalRule(), // 行距
        new LineHeight(), // 增加缩进
        new Indent(), // 减少缩进
        new Preview(),
      ],
      formRules: {
        name: [
          {
            required: true,
            message: '案件名称不能为空',
            trigger: 'blur'
          }
        ],
        caseType: [
          {
            required: true,
            message: '案件类型不能为空',
            trigger: 'blur'
          }
        ]
      },
      showincreaseForm:true,
      search: {
        page: 1,
        pageSize: 10,
        keyword: '',
        name: '',
        seqNo: '',
        caseType: ''
      },
      caseData:[],
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.initialization()
  },
  methods: {
    initialization(){  
      getCase(this.search).then(res => {
        this.increaseForm.seqNo = res.total+1
      })
      getDictionaryByTypeCode({code:'CASETYPE',status:0}).then(res => {
        this.caseData = res
      })
    },
    increaseSaveBtn() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const t = this
          addCase(this.increaseForm).then((res) => {
            t.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('dialogChanged', false)
            this.$refs.addForm.resetFields()
          }).catch(res => {
            console.log(res)
          }) 
        }
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
