<template>
    <div>
        <div slot="header" class="clearfix sf-breadcrumb">
        <!--面包屑-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!--/面包屑-->
        </div>
        <el-card class="card__left">
        <el-button type="primary" plain @click="addDictType()">添加</el-button>
        <el-button type="warning" plain @click="editDictType()">编辑</el-button>
        <div class="block">
            <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            default-expand-all
            :expand-on-click-node="true">
            <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
            <el-button
                type="text"
                size="mini"
                @click="() => append(data)">
                添加
            </el-button>
            <el-button
                type="text"
                size="mini"
                @click="() => remove(node, data)">
                删除
            </el-button>
            </span>
            </span>
            </el-tree>
        </div>
      </el-card>
    </div>
</template>

<script>
let id = 1000;

export default {
  data() {
      const data = [{
        id: 1,
        label: '一级 1',
        children: [{
          id: 4,
          label: '二级 1-1',
        }]
      }, {
        id: 2,
        label: '一级 2',
        children: [{
          id: 5,
          label: '二级 2-1'
        }, {
          id: 6,
          label: '二级 2-2'
        }]
      }, {
        id: 3,
        label: '一级 3',
        children: [{
          id: 7,
          label: '二级 3-1'
        }, {
          id: 8,
          label: '二级 3-2'
        }]
      }];
      return {
          data: JSON.parse(JSON.stringify(data)),
      };
  },
  created() {

  },
  mounted() {

  },
  methods: {
      append(data) {
        const newChild = { id: id++, label: 'testtest', children: [] };
        if (!data.children) {
          this.$set(data, 'children', []);
        }
        data.children.push(newChild);
      },

      remove(node, data) {
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex(d => d.id === data.id);
        children.splice(index, 1);
      },

      renderContent(h, { node, data, store }) {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span>
              <el-button size="mini" type="text" on-click={ () => this.append(data) }>Append</el-button>
              <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>Delete</el-button>
            </span>
          </span>);
      }
  }
};
</script>

<style scoped>
.block{
    margin-top: 20px;
}
.card__left {
  width: 500px;
  margin-right: 20px;  
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding-right: 8px;
  }
</style>
