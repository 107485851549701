<template>
  <el-form ref="addForm" :model="addForm" label-width="80px" size="mini">
    <el-form-item label="印章名称" prop="name">
      <el-input v-model="addForm.name"></el-input>
    </el-form-item>
    <el-form-item label="印章照片" prop="photoPath">
      <el-upload class="centerImg" action="" :class="{ hide: hideUploadIcon }"
                 list-type="picture-card" :on-preview="handlePictureCardPreview"
                 :on-remove="handleRemove" :http-request="uploadImage" :auto-upload="true"
                 :limit="1" ref="uploadicon">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="addForm.photoPath" alt=""/>
      </el-dialog>
    </el-form-item>
    <el-form-item size="large">
      <el-button type="primary" @click="saveSeal">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  addSeal
} from '@/api/seal'
import {
  upload
} from '@/api/common'

export default {
  name: 'increase',
  props: ['dialogIncreaseVisible'],
  data () {
    return {
      addForm: {
        name: '',
        photoPath: ''
      },
      headers: '',
      hideUploadIcon: false,
      dialogVisible: false
    }
  },
  methods: {
    UploadUrl: function () {
      return '/api/Common/fileupload'
    },
    // 保存印章
    saveSeal () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const t = this
          addSeal(this.addForm).then((res) => {
            if (res.success) {
              t.$message({
                message: '保存成功',
                type: 'success'
              })
            } else {
              t.$message({
                message: '保存成功',
                type: 'success'
              })
            }
          }).catch(res => {
            console.log(res)
          })
          this.$emit('increaseChanged', false)
          this.$refs.addForm.resetFields()
        }
      })
    },

    // 文件提交
    uploadImage (param) {
      const formData = new FormData()
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        if (res.success) {
          this.addForm.photoPath = res.data
          console.log(this.addForm)
        }
      })
    },
    handleRemove (file) {
      console.log(file)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>

</style>
