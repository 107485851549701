import request from '@/utils/request'

/**
 * 添加水印
 * @param {*} data
 * @returns
 */
export const addWaterMark = data => {
  return request({
    method: 'POST',
    url: '/api/WaterMark/add',
    data
  })
}
/**
 * 查询水印
 * @param {*} params
 * @returns
 */
export const queryWaterMark = params => {
  return request({
    method: 'GET',
    url: '/api/WaterMark/query',
    params
  })
}
/**
 * 编辑水印
 * @param {*} data
 * @returns
 */
export const editWaterMark = (waterMarkId, data) => {
  return request({
    method: 'PUT',
    url: '/api/WaterMark/edit',
    params: {
      waterMarkId: waterMarkId
    },
    data
  })
}
/**
 * 获取水印Id
 * @param {*} id
 * @returns
 */
export const getWaterMark = id => {
  return request({
    method: 'GET',
    url: `/api/WaterMark/${id}`
  })
}
/**
 * 删除水印
 * @param {*} deleteId
 * @returns
 */
export const deleteWaterMark = deleteId => {
  return request({
    method: 'DELETE',
    url: '/api/WaterMark/delete',
    data: {
      id: deleteId
    }
  })
}
