<template>
  <el-form ref="peopleForm" :model="dictionaryForm" label-width="100px" size="mini" :rules="formRules">
    <!-- <el-form-item label="上级类型" prop="parentId">
      <el-select placeholder="上级类型" v-model="dictionaryForm.parentId" style="float:left;">
        <el-option v-for="item in fristType" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="类型名称" prop="name">
      <el-input v-model="dictionaryForm.name"></el-input>
    </el-form-item>
    <el-form-item label="类型编码" prop="typeCode">
      <el-input v-model="dictionaryForm.typeCode"></el-input>
    </el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>
  </el-form>
</template>

<script>
import { editSysDictionaryType,InquireSysDictionaryType } from '@/api/dictionary'
export default {
  name: 'dictionary-edit',
  props: {
    dictId: {
      type: String,
      default: '0'
    },
    fristType: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dictionaryForm: {
        id: 0,
        parentId: '',
        name: '',
        typeCode: '',
        sort: 0
      },
      formRules: {
        // parentId: [
        //   {
        //     required: true,
        //     message: '不能为空',
        //     trigger: 'blur'
        //   }
        // ],
      }
    }
  },
  mounted () {
    this.loadEditCase()
  },
  methods: {
    loadEditCase () {

      console.log("this.dictId",this.dictId)
      InquireSysDictionaryType(this.dictId).then(res => {
        console.log(res, '666')
        this.dictionaryForm.id = res.DictTypeId
        this.dictionaryForm.parentId = res.ParentId
        this.dictionaryForm.name = res.Name
        this.dictionaryForm.typeCode = res.TypeCode
        this.dictionaryForm.sort = res.Sort
      })
    },
    onSubmit () {
      this.$refs.peopleForm.validate((valid) => {
        if (valid) {
          const t = this
          editSysDictionaryType(this.dictionaryForm).then((res) => {
            console.log("editSysDictionaryType",res)
            t.close('peopleForm')
            if (res.success) {
              console.log("res.success1",res.success)
              t.$message({
                message: res.message,
                type: 'success'
              })
              
            } else {
              console.log("res.success2",res.success)
              t.$message({
                message: '编辑成功',
                type: 'success'
              })
            }
          }).catch(res => {
            console.log(res)
          })
        }
      })
    },
    close (refName) {
      this.$emit('handleClick2', false);
      this.$refs[refName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
