<template>
  <el-form
    ref="addForm"
    :model="increaseForm"
    label-width="120px"
    size="mini"
    :rules="formRules"
  >
    <el-form-item label="角色名:" prop="roleName">
      <el-input v-model="increaseForm.roleName"></el-input>
    </el-form-item>
    <el-form-item label="角色Code:" prop="roleCode">
      <el-input v-model="increaseForm.roleCode"></el-input>
    </el-form-item>
    <el-form-item label="顺 序:" prop="sort">
      <el-input v-model="increaseForm.sort"></el-input>
    </el-form-item>
    <el-form-item label="备 注:" prop="roleDesc">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="increaseForm.roleDesc">
      </el-input>
    </el-form-item>
      <el-button type="primary" @click="onSubmit()">保存</el-button>
  </el-form>
</template>

<script>
import { addSysRole } from '@/api/roles'

export default {
  name: 'Increase',
  data () {
    return {
      increaseForm: {
        roleName: '',
        roleCode: '',
        sort: '',
        status: true,
        roleDesc: ''
      },
      formRules: {
        roleName: [
          {
            required: true,
            message: '角色名不能为空',
            trigger: 'blur'
          }
        ],
        roleCode: [
          {
            required: true,
            message: '角色Code不能为空',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    resetIncreaseForm() {
      this.increaseForm = {
        roleName: '',
        roleCode: '',
        sort: '',
        status: true,
        roleDesc: ''
      }
    },
    onSubmit () {
      alert(11)

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const _this = this
          addSysRole(_this.increaseForm).then((res) => {
            if (res.success) {
              _this.$message({
                message: '保存成功',
                type: 'success'
              })
              _this.$emit('increaseDataChange', false)
            } else {
              _this.$message({
                message: '保存成功',
                type: 'success'
              })
              _this.$emit('increaseDataChange', false)
            }
          }).catch(res => {
            console.log(res)
          })
          _this.resetIncreaseForm()
        }
      })
    },
    close (refName) {
      this.dialogFormVisible = false
      this.$refs[refName].resetIncreaseForm()
    }
  }
}
</script>

