<template>
  <el-form ref="peopleForm" :model="dictionaryForm" label-width="100px" size="mini" :rules="formRules">
    <!-- <el-form-item label="序号" prop="id">
      <el-input v-model="dictionaryForm.id"></el-input>
    </el-form-item> -->
    <el-form-item label="字典名称" prop="dictName">
      <el-input v-model="dictionaryForm.dictName"></el-input>
    </el-form-item>
    <el-form-item label="字典内容" prop="dictContent">
      <el-input v-model="dictionaryForm.dictContent"></el-input>
    </el-form-item>
    <el-form-item label="状态" prop="status">
      <!-- <el-input v-model="dictionaryForm.status"></el-input> -->
      <el-switch
        style="float:left;margin:5px 0 0 0;"
        v-model="dictionaryForm.status"
        active-color="#13ce66"
        inactive-color="#ff4949">
      </el-switch>
    </el-form-item>
    <el-form-item label="排序" prop="sort">
      <el-input v-model="dictionaryForm.sort"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input v-model="dictionaryForm.remark"></el-input>
    </el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>
  </el-form>
</template>

<script>
import { getSysDictionary,editSysDictionary } from '@/api/dictionary'
export default {
  name: 'dictionary-edit2',
  props: {
    dictId2: {
      type: String,
      default: 0
    }
  },
  data () {
    return {
      dictionaryForm: {
        id: 0,
        dictName: '',
        dictContent: '',
        status: '',
        sort: 0,
        remark: 0,
      },
      formRules: {
        // parentId: [
        //   {
        //     required: true,
        //     message: '不能为空',
        //     trigger: 'blur'
        //   }
        // ],
      }
    }
  },
  mounted () {
    this.loadEditCase()
  },
  methods: {
    loadEditCase () {
      // console.log("this.dictId2",this.dictId2)
      getSysDictionary(this.dictId2).then(res => {
        this.dictionaryForm.id = res.DictId
        this.dictionaryForm.dictName = res.DictName
        this.dictionaryForm.dictContent = res.DictContent
        this.dictionaryForm.status = res.Status
        this.dictionaryForm.sort = res.Sort
        this.dictionaryForm.remark = res.Remark
      })
    },
    onSubmit () {
      this.$refs.peopleForm.validate((valid) => {
        if (valid) {
          const t = this
          editSysDictionary(this.dictionaryForm).then((res) => {
            t.close('peopleForm')
            t.$message({
              message: '编辑成功',
              type: 'success'
            })
          }).catch(res => {
            console.log(res)
          })
        }
      })
    },
    close (refName) {
      this.$emit('handleClick3', false);
      this.$refs[refName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
