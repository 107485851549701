<template>
  <el-form
    ref="editForm"
    :model="editForm"
    label-width="120px"
    size="mini"
    :rules="formRules"
  >
    <!-- <el-form-item label="用户编号" prop="id">
      <el-input v-model="editForm.id"></el-input>
    </el-form-item> -->
    <el-form-item label="角色名" prop="roleName">
      <el-input v-model="editForm.roleName"></el-input>
    </el-form-item>
    <el-form-item label="角色Code" prop="roleCode">
      <el-input v-model="editForm.roleCode"></el-input>
    </el-form-item>
    <el-form-item label="顺序" prop="sort">
      <el-input v-model="editForm.sort"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="roleDesc">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="editForm.roleDesc">
      </el-input>
    </el-form-item>
    <el-button type="primary" @click="onSubmit()">保存</el-button>
  </el-form>
</template>

<script>
import { editSysRole, getSysRole } from '@/api/roles'

export default {
  name: 'Edit',
  props: {
    RoleId: {
      type: Number
    }
  },
  data () {
    return {
      editForm: {
        roleId: -1,
        roleName: '',
        roleCode: '',
        sort: '',
        roleDesc: '',
      },
      formRules: {
        roleName: [
          {
            required: true,
            message: '角色名不能为空',
            trigger: 'blur'
          }
        ],
        roleCode: [
          {
            required: true,
            message: '角色Code不能为空',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  created () {
  },
  mounted () {
    console.log(111)
    this.loadSysRole()
  },
  methods: {
    loadSysRole () {
      let _this = this
      console.log(_this.editForm)
      getSysRole(_this.RoleId).then(res => {
        console.log('res',res)
        _this.editForm.roleId = res.data.RoleId
        _this.editForm.roleName = res.data.RoleName
        _this.editForm.roleCode = res.data.RoleCode
        _this.editForm.sort = res.data.Sort
        _this.editForm.roleDesc = res.data.RoleDesc
      }).catch(res => {
        console.log(res)
      })
    },
    onSubmit () {
      let _this = this
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        let roleId = _this.RoleId
        if (roleId) {
          editSysRole(_this.editForm).then(res => {
            _this.editForm = res
            _this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
          _this.$emit('editDataChange', false)
        }
      })
    },
    handleRemove (file) {
      console.log(file)
    }
  }
}
</script>

