<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>系统字典</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <div class="card">
      <!--左侧树状栏目-->
      <el-card class="card__left">
        <el-button type="primary" plain @click="addDictType()">添加</el-button>
        <el-button type="warning" plain @click="editDictType()">编辑</el-button>
        <el-tree
          class="filter-tree"
          node-key="id"
          default-expand-all
          :accordion="true"
          @check="handleCheck"
          :data="treeData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          :default-expanded-keys="openData"
          :node-click="nodeClick"
          ref="tree"
          check-on-click-node>
        </el-tree>
      </el-card>
      <!--/左侧树状栏目-->

      <!--数据表格-->
      <el-card class="card__right">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          style="width:250px"
          >
        </el-input>
        <el-button class="card__right__plus" type="warning" icon="el-icon-plus" @click="addDict()">添加</el-button>
        <el-table :data="tableData" border style="width: 100%; margin: 20px 0">
          <el-table-column prop="DictId" v-model="tableData.DictId" align="center" label="序号" width="60">
          </el-table-column>
          <el-table-column prop="DictName" v-model="tableData.DictName" align="center" label="字典名称">
          </el-table-column>
          <el-table-column prop="DictContent" v-model="tableData.DictContent" align="center" label="字典内容">
          </el-table-column>
          <el-table-column prop="Status" v-model="tableData.Status" align="center" label="状态" width="160">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.Status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="statusChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="Sort" v-model="tableData.Sort" align="center" label="排序" width="160">
          </el-table-column>
          <el-table-column prop="Remark" v-model="tableData.Remark" align="center" label="备注" width="160">
          </el-table-column>
          <el-table-column align="center" label="操作" width="160">
            <template slot-scope="scope">
              <div class="icon-box">
                <a class="btn-edit" @click="editDict(scope.row.DictId)"><i class="el-icon-edit"></i>编辑</a>
                <a class="btn-delete" @click="handelDelete(scope.row.DictId)"><i class="el-icon-delete"></i>删除</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页条-->
        <el-pagination
          class="pagination"
          background
          layout="total, sizes, prev, pager, next"
          :disabled="loading"
          @size-change="changePageSize"
          @current-change="changeCurrentPage"
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          :total="pageInfo.total">
        </el-pagination>
        <!--/分页条-->
      </el-card>
      <!--/数据表格-->
      
    </div>
    <el-dialog class="increaseForm" title="添加字典类型" append-to-body :visible.sync="dialogFormVisible"
               :close-on-click-modal="false">
      <increase @handleClick1="handleClick1" :dictId="dictId" v-if="refresh1" :fristType="fristType"/>
    </el-dialog>
    <el-dialog class="increaseForm" title="编辑字典类型" append-to-body :visible.sync="dialogEditFormVisible"
               :close-on-click-modal="false">
      <edit @handleClick2="handleClick2" :dictId="dictId" v-if="refresh1" :fristType="fristType"/>
    </el-dialog>
    <el-dialog class="increaseForm" title="编辑字典" append-to-body :visible.sync="dialogEditFormVisible2"
               :close-on-click-modal="false">
      <edit2 @handleClick3="handleClick3" :dictId2="dictId2" v-if="refresh1"/>
    </el-dialog>
    <el-dialog class="increaseForm" title="添加字典" append-to-body :visible.sync="dialogEditFormVisible3"
               :close-on-click-modal="false">
      <increase2 @handleClick4="handleClick4" :dictId="dictId" v-if="refresh1"/>
    </el-dialog>
  </div>
</template>

<script>
import Increase from './components/dictionary-increase'
import Edit from './components/dictionary-edit'
import Edit2 from './components/dictionary-edit2'
import Increase2 from './components/dictionary-increase2'

import { addSysDictionary,addSysDictionaryType,editSysDictionary,getSysDictionaryType,InquireSysDictionaryType,getQuery,deleteSysDictionary } from '@/api/dictionary'

export default {
  name: 'dictionary',
  components: {
    increase: Increase,
    edit: Edit,
    edit2: Edit2,
    increase2: Increase2,
  },
  watch: {
    filterText (val) {
      // this.$refs.tree.filter(val)
      this.search.keyword=val
      this.getTable()
    }
  },
  data () {
    return {
      dictId: 0,
      dictId2: 0,
      dictId3: 0,
      filterText: '',
      // treeData: [{
      //   ParentId: 0,
      //   Name: '',
      //   TypeCode: '',
      //   sort: 0
      // }],
      tableData: [],
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      dialogEditFormVisible2: false,
      dialogEditFormVisible3: false,
      treeData: [],
      openData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //字典
      Query:[],
      tableData2: [],
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: '',
        // dictTypeId: '',
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      loading: true, // 表单数据加载中 loading
      refresh1: true,
      fristType:[]
    }
  },
  mounted () {
    this.initialization()
    
    
  },
  methods: {
    async initialization(){
      await this.getTree()
      await this.getTable()
    },
    statusChange(val){
      let list={
        id: val.DictId,
        dictName: val.DictName,
        dictContent: val.DictContent,
        status: val.Status,
        sort: val.Sort,
        remark: val.Remark
      }
      editSysDictionary(list).then((res) => {
      }).catch(res => {
        console.log(res)
      })
    },
    refresh(){
      this.refresh1=false
      this.$nextTick(() => {
          this.refresh1=true
      });
    },
    addDictType(){
      this.dictId=this.fristType[0].value
      this.refresh()
      this.dialogFormVisible = true
    },
    editDictType(){
      this.dictId=this.$refs.tree.getCheckedKeys()[0]
      if(this.dictId){
        this.refresh()
        this.dialogEditFormVisible = true
      }else{
        this.$message({
          message: '请先选择',
          type: 'warning'
        })
      }
      
    },
    editDict(DictId){
      this.refresh()
      this.dictId2=DictId
      this.dialogEditFormVisible2=true
    },
    addDict(){
      this.dictId=this.$refs.tree.getCheckedKeys()[0]
      let tf=true
      for(let i of this.fristType){
        if(i.value==this.dictId){
          tf=false
        }
      }
      if(tf){
        this.refresh()
        this.dialogEditFormVisible3=true
      }else{
        this.$message({
          message: '请正确选择字典类型',
          type: 'warning'
        })
      }
      
    },
    handelDelete(dictId){
      let _this = this
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSysDictionary(dictId).then((res) => {
          _this.getTable()
        })
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleClick1(val){
      this.dialogFormVisible=val
      this.getTree()
    },
    handleClick2(val){
      this.dialogEditFormVisible=val
      this.getTree()
    },
    handleClick3(val){
      this.dialogEditFormVisible2=val
      this.getTable()
    },
    handleClick4(val){
      this.dialogEditFormVisible3=val
      this.getTable()
    },
    handleCheck(nodes, resolve) {
      // console.log("nodes",nodes)
      // console.log("resolve",nodes)
      if (resolve.checkedKeys.length > 0) {
        this.$refs.tree.setCheckedKeys([nodes.id]);
      }
      // this.dictId=nodes.id

      this.search={
        page: 1,
        pageSize: 10,
        // keyword: '',
        dictTypeId: nodes.id,
      },
      this.getTable()
      // InquireSysDictionaryType(nodes.id).then(res => {
      //   console.log("resres",res)
      // })
      
    },
    getTable(){
      this.loading = true
      this.tableData=[]
      getQuery(this.search).then(res => {
        if(res.data){
          this.tableData=res.data
        }
        this.pageInfo.total=res.total
        this.pageInfo.pageSize=res.pageSize
        this.pageInfo.currentPage=res.currentPage
        // 关闭加载中 loading
        this.loading = false
      })
      
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.getTable() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.getTable()
    },
    nodeClick(data){
      console.log("nodeClick",data)
    },
    getTree(){
      getSysDictionaryType().then(res => {
        // console.log("getSysDictionaryType res",res)
        let list=[]
        this.fristType=[]
        this.dictId=res[0].id
        for(let i=0;i<res.length;i++){
          this.fristType.push({value:res[i].id,label:res[i].title})
          list.push({
            id:res[i].id,
            label:res[i].title,
            children:[],
          })
          if(res[i].open){
            this.openData.push(parseInt(res[i].id))
          }
          for(let j=0;j<res[i].children.length;j++){
            list[i].children.push({
              id:res[i].children[j].id,
              label:res[i].children[j].title,
            })
            if(res[i].children[j].open){
              // this.openData.push(parseInt(res[i].children[j].id))
            }
          }
        }
        this.treeData=list
        // console.log("list",list)
        // console.log("this.openData",this.openData)
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  width: 100%;
}
.card__left {
  width: 400px;
  margin-right: 20px;  
}

.el-input {
  width: 54%;
  margin-right: 10px;
}
.card__right {
  flex: 1;
}

.filter-tree{
  height: 700px;
  overflow: auto;
}
</style>
