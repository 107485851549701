<template>
  <el-form ref="form" :model="videoEditForm" label-width="110px" size="mini">
    <el-form-item label="被询问号">
      <el-input v-model="videoEditForm.inquiried"></el-input>
    </el-form-item>
    <el-form-item label="当事人">
      <el-input v-model="videoEditForm.people"></el-input>
    </el-form-item>
    <el-form-item label="询问室号">
      <el-input v-model="videoEditForm.inquryRoom"></el-input>
    </el-form-item>
    <el-button type="primary" @click="videoEditSaveBtn">保存</el-button>
  </el-form>
</template>

<script>

export default {
  name: 'edit',
  components: {},
  data () {
    return {
      videoEditForm: {
        inquiried: '被询问号',
        people: '当事人',
        inquryRoom: '询问室号'
      }
    }
  },
  methods: {
    videoEditSaveBtn () {
      this.$message({
        message: '恭喜你，保存成功！',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
