// 用户角色相关请求模块
import request from '@/utils/request'

// 根据角色id获取角色信息
export const getSysRole = id => {
  return request({
    method: 'GET',
    url: `/api/SysRole/${id}`
  })
}

//添加角色
export const addSysRole = data => {
    return request({
      method: 'POST',
      url: '/api/SysRole/add',
      data
    })
}

//删除角色
export const deleteSysRole = id => {
    return request({
      method: 'DELETE',
      url: '/api/SysRole/delete',
      data:{
          id
      }
    })
}

//修改角色
export const editSysRole = data => {
    return request({
      method: 'PUT',
      url: '/api/SysRole/edit',
      data
    })
}

//修改角色状态
export const editSysRoleStatus = data => {
    return request({
      method: 'PUT',
      url: '/api/SysRole/editstatus',
      data
    })
}

// 查询列表
export const querySysRole = param => {
    return request({
      method: 'GET',
      url: `/api/SysRole/query`,
      param
    })
}