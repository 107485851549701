<template>
	<div class="article-container sf">
		<div slot="header" class="clearfix sf-breadcrumb">
			<!--面包屑-->
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/">首页</el-breadcrumb-item>
				<el-breadcrumb-item>系统管理</el-breadcrumb-item>
				<el-breadcrumb-item>角色管理</el-breadcrumb-item>
			</el-breadcrumb>
			<!--/面包屑-->
		</div>
		<el-card class="filter-card">

			<!--数据表格筛选-->
			<el-form ref="form" :model="form" label-width="40px">
				<el-row :gutter="10">
					<el-col :span="24" class="text-align-r">
						 <el-button
							type="warning"
							icon="el-icon-plus"
							@click="handleIncrease()">添加角色
							</el-button>
					</el-col>
				</el-row>

			</el-form>
			<!--/数据表格筛选-->

			<!--数据表格-->
			<el-table :data="tableData" border v-loading="loading" style="width: 100%; margin: 20px 0">
				<el-table-column prop="RoleId" v-model="tableData.RoleId" align="center" label="序号" width="60">
				    <template slot-scope="scope">
            			<span v-text="getIndex(scope.$index)"> </span>
          			</template>
				</el-table-column>
				<el-table-column prop="RoleName" v-model="tableData.RoleName" align="center" label="角色名称">
				</el-table-column>
				<el-table-column prop="RoleCode" v-model="tableData.RoleCode" align="center" label="角色Code">
				</el-table-column>
				<el-table-column prop="Sort" v-model="tableData.Sort" align="center" label="顺序">
				</el-table-column>
				<el-table-column prop="Status" v-model="tableData.Status" align="center" label="状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.Status"
							active-color="#13ce66"
							inactive-color="#ff4949"
							@change="statusChange(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="roleDesc" v-model="tableData.roleDesc" align="center" label="备注">

				</el-table-column>
				<el-table-column prop="roOperate" align="center" label="操作" width="130">
					<template slot-scope="scope">
						<div class="icon-box">
							<a class="btn-edit" @click="handleUpdate(scope.row.RoleId)"><i class="el-icon-edit"></i>编辑</a>
							<a class="btn-delete" @click="handelDelete(scope.row.RoleId)"><i class="el-icon-delete"></i>删除</a>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<!--/数据表格-->
			<!--分页条-->
			<el-pagination
				class="pagination"
				@size-change="changePageSize"
				@current-change="changeCurrentPage"
				background
				:disabled="loading"
				layout="total, sizes, prev, pager, next"
				:current-page="pageInfo.currentPage"
				:page-size="pageInfo.pageSize"
				:total="pageInfo.total"
			>
			</el-pagination>
		</el-card>
		<el-dialog
			class="increaseForm"
			title="新增角色"
			append-to-body
			:visible.sync="dialogIncreaseFormVisible"
			:close-on-click-modal="false">
			<Increase @increaseDataChange="increaseDataChange()"/>
		</el-dialog>
		<el-dialog
            class="increaseForm"
            title="编辑角色"
            append-to-body
			v-if="dialogEditFormVisible"
            :visible.sync="dialogEditFormVisible"
            :close-on-click-modal="false">
            <Edit :RoleId="roleId" @editDataChange="editDataChange($event)"/>
        </el-dialog>
	</div>
</template>

<script>
	import Increase from './components/roles-increase'
	import Edit from './components/roles-edit'
	import { deleteSysRole,editSysRoleStatus,querySysRole } from '@/api/roles'

	export default {
		name: 'Roles',
		components: {
			Increase: Increase,
			Edit:Edit
		},
		props: {},
		data() {
			return {
				form: {},
				// 查询条件
				search: {
					page: 1,
					pageSize: 10,
					keyword: ''
				},
				// 分页信息
				pageInfo: {
					total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
					pageSize: 10, // 默认每页数据量
					currentPage: 1
				},
				roleId:-1,
				tableData: [],
				loading: false, // 表单数据加载中 loading
				dialogIncreaseFormVisible: false,
				dialogEditFormVisible: false,
			}
		},
		computed: {},
		watch: {},
		created() {
			this.loadRoles(1)
		},
		mounted() {
		},
		methods: {
			// 加载默认用户数据
			loadRoles (page = 1) {
				let _this = this
				// 展示加载中 loading
				querySysRole(_this.search).then((res) => {
					console.log(res)
					_this.pageInfo.currentPage = res.currentPage
					_this.pageInfo.pageSize = res.pageSize
					_this.pageInfo.total = res.total
					_this.tableData = res.data
					// 关闭加载中 loading
					_this.loading = false
				})
			},
			changePageSize (pageSize) {
				this.search.pageSize = pageSize
				this.search.page = 1
				this.loadRoles()
			},
			getIndex ($index) {
				return (this.pageInfo.currentPage - 1) * this.pageInfo.pageSize + $index + 1
			},
			changeCurrentPage (page) {
				this.search.page = page
				this.loadRoles()
			},
			// 编辑事件
			handleUpdate (roleId) {
				console.log('roleId',roleId)
				this.roleId = roleId
				this.dialogEditFormVisible = true
			},
			handleIncrease () {
				this.dialogIncreaseFormVisible = true
			},
			handelDelete(roleId){
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteSysRole(roleId).then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.loadRoles(this.page)
					})
				}).catch(() => {
					this.$message({
					type: 'info',
					message: '已取消删除'
					})
				})
			},
			statusChange(row){
				editSysRoleStatus({id:row.RoleId,status:row.Status})
			},
			increaseDataChange(){
				this.dialogIncreaseFormVisible = false
				this.loadRoles(this.page)
			},
			editDataChange(value){
				this.dialogEditFormVisible = value
				this.loadRoles(this.page)
			}
		}
	}
</script>

<style>
	.filter-card {
		margin-bottom: 30px;
	}

	.list-table {
		margin-bottom: 20px;
	}

	.article-cover {
		width: 60px;
		background-size: cover;
	}

	.pagination {
		display: flex;
		justify-content: flex-end;
	}

	.increaseForm {
		text-align: center;
	}

	.el-select .el-input {
		width: 130px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	.sf-breadcrumb {
		padding-bottom: 13px;
		border-bottom: #cbddff 1px solid;
		margin-bottom: 20px;
	}

	.sf-breadcrumb .el-breadcrumb__inner a,
	.el-breadcrumb__inner.is-link {
		font-weight: normal !important;
	}

	.text-align-r {
		text-align: right;
	}

	.sf-search-more {
		border: #d8e3f8 1px solid;
		border-radius: 4px;
		padding: 20px 20px 0 20px;
		margin-top: 20px;
		background: #e7effd;
	}


</style>
