<template>
  <el-form ref="editForm" :model="editForm" label-width="80px" size="mini">
    <el-form-item label="水印Id" prop="waterMarkId" style="display: none">
      <el-input v-model="editForm.waterMarkId"></el-input>
    </el-form-item>
    <el-form-item label="水印内容" prop="content">
      <el-input v-model="editForm.content"></el-input>
    </el-form-item>

    <el-form-item label="水印描述" prop="describe">
      <el-input type="textarea" placeholder="请输入内容" v-model="editForm.describe" maxlength="130"
                show-word-limit>
      </el-input>
    </el-form-item>
    <el-button type="primary" @click="editSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import { editWaterMark, getWaterMark } from '@/api/waterMark'

export default {
  name: 'edit',
  props: {
    dialogEditFormVisible: {
      type: Boolean,
      default: true // 默认值
    },
    WaterMarkId: {
      type: Number,
      default: 123
    }
  },
  data () {
    return {
      editForm: {
        content: '',
        describe: '',
        waterMarkId: 0
      }
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.loadWaterMark()
  },
  methods: {
    loadWaterMark () {
      getWaterMark(this.WaterMarkId).then(res => {
        this.editForm.content = res.Content
        this.editForm.describe = res.Describe
        this.editForm.waterMarkId = res.WaterMarkId
      })
    },
    editSaveBtn () {
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        const WaterMarkId = this.WaterMarkId
        if (WaterMarkId) {
          editWaterMark(WaterMarkId, this.editForm).then(res => {
            this.editForm = res.data
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
          this.$emit('editChanged', false)
          this.loadWaterMark()
        }
      })
    },
    closeBtn () {
      this.$emit('editChanged', false)
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
