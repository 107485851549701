// 用户管理请求模块
import request from '@/utils/request'

/**
 * 获取用户基本信息
 * @param {*} id
 * @returns
 */
export const getUserInfo = id => {
  return request({
    method: 'GET',
    url: `/api/UserInfo/${id}`
  })
}

/**
 * 添加用户基本信息
 * @param {*} data
 * @returns
 */
export const addUserInfo = data => {
  return request({
    method: 'POST',
    url: '/api/UserInfo/add',
    data
  })
}

/**
 * 删除用户基本信息
 * @param {*} deleteId
 * @returns
 */
export const deleteUserInfo = deleteId => {
  return request({
    method: 'DELETE',
    url: '/api/UserInfo/delete',
    data: {
      id: deleteId
    }
  })
}

/**
 * 编辑用户基本信息
 * @param {*} data
 * @returns
 */
export const editUserInfo = (UserId, data) => {
  return request({
    method: 'PUT',
    url: '/api/UserInfo/edit',
    params: {
      id: UserId
    },
    data
  })
}

/**
 * 查询用户基本信息
 * @param {*} params
 * @returns
 */
export const queryUserInfo = params => {
  return request({
    method: 'GET',
    url: '/api/UserInfo/query',
    params
  })
}

/**
 * 重置用户密码
 * @param {*} userId
 * @returns
 */
 export const resetPassword = userId => {
  return request({
    method: 'PUT',
    url: '/api/UserInfo/resetPassword',
    data: {
      id: userId
    }
  })
}

/**
 * 改变用户状态
 * @param {*} data
 * @returns
 */
 export const editStatus = data => {
  return request({
    method: 'PUT',
    url: '/api/UserInfo/editStatus',
    data
  })
}
