// 印章管理请求模块
import request from '@/utils/request'

/**
 * 获取印章信息
 * @param {*} id
 * @returns
 */
export const getSealId = id => {
  return request({
    method: 'GET',
    url: `/api/Seal/${id}`
  })
}

/**
 * 添加印章信息
 * @param {*} data
 * @returns
 */
export const addSeal = data => {
  return request({
    method: 'POST',
    url: '/api/Seal/add',
    data
  })
}

/**
 * 删除印章信息
 * @param {*} deleteId
 * @returns
 */
export const deleteSeal = deleteId => {
  return request({
    method: 'DELETE',
    url: '/api/Seal/delete',
    data: {
      id: deleteId
    }
  })
}

/**
 * 编辑印章信息
 * @param {*} data
 * @returns
 */
export const editSeal = (SealId, data) => {
  return request({
    method: 'PUT',
    url: '/api/Seal/edit',
    params: {
      id: SealId
    },
    data
  })
}

/**
 * 查询印章信息
 * @param {*} params
 * @returns
 */
export const querySeal = params => {
  return request({
    method: 'GET',
    url: '/api/Seal/query',
    params
  })
}
