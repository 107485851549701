<template>
  <el-form
    ref="editForm"
    :model="editForm"
    label-width="80px"
    size="mini"
    :rules="formRules"
  >
    <!-- <el-form-item label="用户编号" prop="id">
      <el-input v-model="editForm.id"></el-input>
    </el-form-item> -->
    <el-form-item label="姓 名" prop="name">
      <el-input v-model="editForm.name"></el-input>
    </el-form-item>
    <el-form-item label="性 别" prop="sex">
      <el-select
        class="select-type"
        v-model="editForm.sex"
        placeholder="请选择性别"
      >
        <el-option label="男" value="男"></el-option>
        <el-option label="女" value="女"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="手机号码" prop="phone">
      <el-input v-model="editForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="用户头像" prop="headImage">
      <el-upload class="avatar-uploader"
                    :show-file-list="false"
                    :on-remove="handleRemove" :http-request="uploadImage"
                    >
          <img v-if="editForm.headImage" :src="('http://fw.zsyunsu.com/'+editForm.headImage)" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      <!-- <el-upload class="centerImg" action="" :class="{ hide: hideUploadIcon }"
                 list-type="picture-card" :on-remove="handleRemove" :http-request="uploadImage" :auto-upload="true"
                 :limit="1">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" v-model="editForm.headImage">
        <img width="100%" :src="('http://fw.zsyunsu.com/'+editForm.headImage)" alt="">
      </el-dialog> -->
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="editForm.remark">
      </el-input>
    </el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>
  </el-form>
</template>

<script>
import { editUserInfo, getUserInfo } from '@/api/userInfo'
import {
  upload
} from '@/api/common'

export default {
  name: 'edit',
  props: {
    dialogEditFormVisible: {
      type: Boolean,
      default: true // 默认值
    },
    UserId: {
      type: Number,
      default: 123
    }
  },
  data () {
    return {
      editForm: {
        id: 0,
        name: '',
        sex: '',
        phone: '',
        headImage: '',
        remark: ''
      },
      dialogVisible: false,
      hideUploadIcon: false,
      formRules: {
        userName: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.editUserInfo()
  },
  mounted () {
    this.loadEditUserInfo()
  },
  methods: {
    loadEditUserInfo () {
      getUserInfo(this.UserId).then(res => {
        console.log(this.editForm, '3334')
        this.editForm.id = res.UserId
        this.editForm.name = res.Name
        this.editForm.phone = res.Phone
        this.editForm.sex = res.Sex
        // res.HeadImage = this.editForm.headImage
        this.editForm.headImage = res.HeadImage
        this.editForm.remark = res.Remark
        console.log(this.editForm.headImage)
      }).catch(res => {
        console.log(res)
      })
    },
    // 文件提交
    uploadImage (param) {
      const formData = new FormData()
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.editForm.headImage = res.data
          console.log(this.editForm, '111221')
        }
      })
    },
    onSubmit () {
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        const UserId = this.UserId
        console.log(UserId, '999')
        if (UserId) {
          editUserInfo(UserId, this.editForm).then(res => {
            console.log(res, '111')
            this.editForm = res.data
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
          this.$emit('editChanged', false)
          this.loadEditUserInfo()
          console.log(this.editForm, '221')
        }
      })
    },
    handleRemove (file) {
      console.log(file)
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
</style>
