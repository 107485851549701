<template>
  <el-form
    ref="checkForm"
    :model="checkForm"
    label-width="80px"
    size="mini"
    class="checkForm"
  >
    <el-form-item label="用户编号" prop="id">{{checkForm.id}}</el-form-item>
    <el-form-item label="用户名" prop="userName">{{checkForm.userName}}</el-form-item>
    <el-form-item label="姓 名" prop="name">{{checkForm.name}}</el-form-item>
    <el-form-item label="性 别" prop="sex">{{checkForm.sex}}</el-form-item>
    <el-form-item label="手机号码" prop="phone">{{checkForm.phone}}</el-form-item>
    <el-form-item label="用户头像" prop="headImage"><img :src="('http://fw.zsyunsu.com/'+checkForm.headImage)" width="160px" height="160px" /></el-form-item>
    <el-form-item label="备注" prop="remark">{{checkForm.remark}}</el-form-item>
  </el-form>
</template>

<script>
import { editUserInfo, getUserInfo } from '@/api/userInfo'

export default {
  name: 'check',
  props: {
    dialogCheckFormVisible: {
      type: Boolean,
      default: true // 默认值
    },
    UserId: {
      type: Number,
      default: 123
    }
  },
  data () {
    return {
      checkForm: {
        id: 0,
        userName: '',
        password: '',
        name: '',
        sex: '',
        phone: '',
        remark: ''
      }
    }
  },
  created () {
    this.editUserInfo()
  },
  mounted () {
    this.loadEditUserInfo()
  },
  methods: {
    loadEditUserInfo () {
      getUserInfo(this.UserId).then(res => {
        this.checkForm.id = res.UserId
        this.checkForm.userName = res.UserName
        this.checkForm.password = res.Password
        this.checkForm.name = res.Name
        this.checkForm.phone = res.Phone
        this.checkForm.sex = res.Sex
        this.checkForm.headImage = res.HeadImage
        this.checkForm.remark = res.Remark
      }).catch(res => {
        console.log(res)
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
.checkForm{
  text-align: left;
}
</style>
