<template>
  <el-form ref="addForm" :model="increaseForm" label-width="80px" size="mini" v-loading="loading" element-loading-text="保存中...请稍等">

    <el-form-item label="模板名称" prop="name">
      <el-input v-model="increaseForm.name"></el-input>
    </el-form-item>
    <el-form-item label="文档分类" prop="docType">
      <el-select class="select-type" v-model="increaseForm.docType" placeholder="请选择文档分类">
        <el-option v-for="item in docData" :key="item.Name" :label="item.Name" :value="item.Name"></el-option>
        <!-- <el-option label="询问通知书模版" value="询问通知书模版"></el-option>
        <el-option label="权利义务告知书模板" value="权利义务告知书模板"></el-option>
        <el-option label="询问笔录模板" value="询问笔录模板"></el-option>
        <el-option label="辨认说明模板" value="辨认说明模板"></el-option>
        <el-option label="调取证据通知书模板" value="调取证据通知书模板"></el-option>
        <el-option label="调取证据材料清单" value="调取证据材料清单"></el-option>
        <el-option label="讯问笔录模板" value="讯问笔录模板"></el-option>
        <el-option label="辨认笔录模板" value="辨认笔录模板"></el-option> -->
      </el-select>
    </el-form-item>

    <!-- <el-form-item label="文件模板" prop="templateContent" style="margin-bottom: 30px;">
      <el-tiptap
        v-model="increaseForm.templateContent"
        :extensions="extensions"
        height="350"
        lang="zh"
        placeholder="请输入文件模板"
      ></el-tiptap>
    </el-form-item> -->
    <iframe id="wordOnlineIframe" :src="wordOnlineUrl" frameborder="0" class="pc iframe wordOnlineIframe" scrolling="auto" style="width: 100%;height: 71vh"></iframe>
    <el-button type="primary" @click="openScreen">全屏显示</el-button>
    <!-- <el-button type="primary" @click="ifFullScreen = false" v-if="ifFullScreen">关闭全屏</el-button> -->
    <el-button type="primary" @click="templateIncreaseSaveBtn">提交</el-button>

  </el-form>
</template>

<script>
import {
  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  HorizontalRule,
  Fullscreen,
  Preview,
  CodeBlock,
  TextColor
} from 'element-tiptap'
import 'element-tiptap/lib/index.css'
import { addTemplate } from '@/api/template'
import { createWordOnlineUrl,getWordOnlineUrl } from '@/api/wordOnline'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'increase',
  components: {
    'el-tiptap': ElementTiptap
  },
  props: ['dialogFormVisible'],
  data () {
    return {
      loading: false,
      increaseForm: {
        name: '',
        docType: '',
        // templateContent: '',
        fileId:'',
      },
      docData:[],
      wordOnlineUrl:'', //在线word读取地址
      // ifFullScreen:false,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 3 }),
        new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
        new Underline(), // 下划线
        new Italic(), // 斜体
        new Strike(), // 删除线
        new HorizontalRule(), // 华丽的分割线
        new ListItem(),
        new BulletList(), // 无序列表
        new OrderedList(), // 有序列表
        new TodoItem(),
        new TodoList(),
        new Fullscreen(),
        new Preview(),
        new CodeBlock(),
        new TextColor()
      ]
    }
  },
  mounted() {
    let timestamp = String(new Date().getTime())
    const fileName = 'document_'+timestamp
    const bussinessId = timestamp
    createWordOnlineUrl(fileName,bussinessId).then((res) => {
          if (res) {
              this.increaseForm.fileId = res.FileId
              getWordOnlineUrl(this.increaseForm.fileId,true).then((res) => {
                if (res) {
                  // let url = res.Url.replace("oos","fw").replace("/we/","/wosedit/").replace("/wv/","/wosview/")
                  this.wordOnlineUrl = res.Url
                }
              })
          }
    })
    getDictionaryByTypeCode({code:'DOCTYPE',status:0}).then(res => {
        this.docData = res
    })
  },
  watch:{
  },
  methods: {
    templateIncreaseSaveBtn () {
      let _this = this
      _this.loading = true
      setTimeout(function(){
        _this.loading = false
        _this.$refs.addForm.validate((valid) => {
                if (valid) {
                  console.log('increaseForm',_this.increaseForm)
                  addTemplate(_this.increaseForm).then((res) => {
                    if (res.success) {
                      _this.$message({
                        message: '保存成功',
                        type: 'success'
                      })
                    } else {
                      _this.$message({
                        message: '保存成功',
                        type: 'success'
                      })
                      _this.$emit('dialogChanged', false)
                      _this.$refs.addForm.resetFields()
                    }
                  }).catch(res => {
                    console.log(res)
                  })
                }
              })
      },5000)

      
    },
    openScreen(){
      var el = document.getElementById("wordOnlineIframe");
          ( el.requestFullscreen
      || el.webkitRequestFullScreen
      || el.mozRequestFullScreen 
      || el.msRequestFullscreen).call(el)
    }
  },
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
