/**
 * @file scenes.js
 * @desc 场景通用化js
 * @author wps-developer
 */

/**
 * @desc 判断环境是在服务器上还是在本地上
 * @returns { bool } true代表本地，false代表服务器
 */
function checkLocalMode () {
  return window.location.host.length < 1
}

/**
 * @desc 返回文件全路径
 * @param { String } fileName 文件名
 * @param { Number } type 文件类型
 * @returns { String } 文件全路径
 */
function getPathByFileName (fileName, type) {
  var hostName

  if (checkLocalMode()) {
    var index = window.location.pathname.lastIndexOf('browser-integration-wps')
    var start = 0
    if (isWindowsPlatform()) {
      start = 1
    }
    hostName = window.location.pathname.substr(start, index - 1)
  } else {
    hostName = window.location.href
    var index = window.location.href.lastIndexOf('browser-integration-wps')
    hostName = hostName.substr(0, index - 1)
  }
  var realPath = hostName + '/' + 'browser-integration-wps/' + type + '/'
  if (undefined != fileName) {
    realPath = realPath + fileName
  }

  realPath = decodeURI(realPath)

  return realPath
}

/**
 * @desc 打开本地或者服务器上的文档
 */
export function openDocumentFromSever () {
  var fileURL = getPathByFileName('header_footer.wps', 'file')
  fileURL = 'http://192.168.1.12:30000/svg/test.doc'
  console.log(fileURL,'fileURL')
  //console.log(checkLocalMode());
  // if (checkLocalMode()) {
  // 	window.wpsFrame.openDocumentByLocal(fileURL, false);
  // } else {
  window.wpsFrame.openDocumentBySever(fileURL, false)
  //}

  if (isWindowsPlatform()) {
    window.wpsFrame.Application = window.wpsFrame.pluginObj.Application
  }
}

/**
 * @desc 新建一个文档
 */
function newDocument () {
  window.wpsFrame.pluginObj.createDocument('wps')
  if (isWindowsPlatform()) {
    window.wpsFrame.Application = window.wpsFrame.pluginObj.Application
  }
}

/**
 * 初始化代码
 */
export  function init ( isNewDocument) {
  if (undefined == isNewDocument || false == isNewDocument) {
    window.wpsFrame.initPlugin('wps', openDocumentFromSever)
  } else {
    window.wpsFrame.initPlugin('wps', newDocument)
  }
}

/**
 * @file scenes01.js
 * @desc 打开和保存服务器文件相关函数
 * @author wps-developer
 */

/**
 * @desc 保存当前文档到服务器
 */
function saveFileForSever () {
  var uploadURL = prompt('请输入要上传的服务器地址')
  if (0 == uploadURL.length || null == uploadURL) {
    alert('服务器地址不能为空')
  } else {
    var ret = window.wpsFrame.saveFileToSever(uploadURL, 'test1.doc')
    console.log(ret)
  }
}

