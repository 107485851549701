<template>
  <el-form
    ref="addForm"
    :model="increaseForm"
    label-width="80px"
    size="mini"
    :rules="formRules"
  >
    <el-form-item label="用户名" prop="userName">
      <el-input v-model="increaseForm.userName"></el-input>
    </el-form-item>
    <el-form-item label="密 码" prop="password">
      <el-input v-model="increaseForm.password"></el-input>
    </el-form-item>
    <el-form-item label="姓 名" prop="name">
      <el-input v-model="increaseForm.name"></el-input>
    </el-form-item>
    <el-form-item label="性 别" prop="sex">
      <el-select
        class="select-type"
        v-model="increaseForm.sex"
        placeholder="请选择性别"
      >
        <el-option label="男" value="男"></el-option>
        <el-option label="女" value="女"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="手机号码" prop="phone">
      <el-input v-model="increaseForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="用户头像" prop="headImage">
            <el-upload class="avatar-uploader"
                    :show-file-list="false"
                    :on-remove="handleRemove" :http-request="uploadImage"
                    >
          <img v-if="increaseForm.headImage" :src="('http://fw.zsyunsu.com/'+increaseForm.headImage)" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </el-form-item>
    <!-- <el-form-item label="角 色">
      <el-select
        class="select-type"
        v-model="increaseForm.type"
        placeholder="请选择角色"
      >
        <el-option label="管理员" value="管理员"></el-option>
        <el-option label="测试员" value="测试员"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item size="large">
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { addUserInfo } from '@/api/userInfo'
import {upload} from '@/api/common'

export default {
  name: 'increase',
  data () {
    return {
      increaseForm: {
        userName: '',
        password: '',
        name: '',
        sex: '',
        phone: '',
        headImage: '',
        // type: [],
        remark: ''
      },
      formRules: {
        userName: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    resetIncreaseForm() {
      this.increaseForm = {
        userName: '',
        password: '',
        name: '',
        sex: '',
        phone: '',
        headImage: '',
        remark: ''
      }
    },
    onSubmit () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const t = this
          addUserInfo(this.increaseForm).then((res) => {
            if (res.success) {
              t.$message({
                message: res.message,
                type: 'success'
              })
              t.$emit('increaseDataChange', false)
            } else {
              t.$message({
                message: res.message,
                type: 'success'
              })
              t.$emit('increaseDataChange', false)
            }
          }).catch(res => {
            console.log(res)
          })
          this.resetIncreaseForm()
        }
      })
    },
    // 文件提交
    uploadImage (param) {
      const formData = new FormData()
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        if (res.success) {
          this.increaseForm.headImage = res.data
        }
      })
    },
    close (refName) {
      this.dialogFormVisible = false
      this.$refs[refName].resetFields()
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
</style>
