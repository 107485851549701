<template>
  <el-form ref="editForm" :model="caseEditForm" label-width="80px" size="mini" :rules="formRules">
    <!-- <el-form-item label="案件序号" prop="caseId">
      <el-input v-model="caseEditForm.caseId"></el-input>
    </el-form-item> -->
    <el-form-item label="案件编号" prop="seqNo">
      <el-input v-model="caseEditForm.seqNo"></el-input>
    </el-form-item>
    <el-form-item label="案件名称" prop="name">
      <el-input v-model="caseEditForm.name"></el-input>
    </el-form-item>
    <el-form-item label="案件类型" prop="caseType">
      <el-select class="select-type" v-model="caseEditForm.caseType" placeholder="请选择活动区域">
        <el-option v-for="item in caseData" :key="item.Name" :label="item.Name" :value="item.Name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="案件简述" prop="resume">
      <el-input type="textarea" placeholder="请输入内容" v-model="caseEditForm.resume" maxlength="30"
                show-word-limit>
      </el-input>
    </el-form-item>
    <el-form-item label="案件详述" prop="detail" style="margin-bottom: 30px;">
      <el-tiptap
        v-model="caseEditForm.detail"
        :extensions="extensions"
        height="350"
        lang="zh"
        placeholder="请输入案件内容"
      ></el-tiptap>
    </el-form-item>
    <el-button type="primary" @click="editSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import {
  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  HorizontalRule,
  Fullscreen,
  Preview,
  CodeBlock,
  TextColor
} from 'element-tiptap'
import 'element-tiptap/lib/index.css'
import { editCase, getCaseId } from '@/api/case'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'edit',
  components: {
    'el-tiptap': ElementTiptap
  },
  props: {
    dialogEditFormVisible: {
      type: Boolean,
      default: true // 默认值
    },
    CaseId: {
      type: Number,
      default: 123
    }
  },
  data () {
    return {
      caseData: [],
      caseEditForm: {
        seqNo: '',
        name: '',
        caseType: '',
        resume: '',
        detail: '',
        caseId: ''
      },
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 3 }),
        new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
        new Underline(), // 下划线
        new Italic(), // 斜体
        new Strike(), // 删除线
        new HorizontalRule(), // 华丽的分割线
        new ListItem(),
        new BulletList(), // 无序列表
        new OrderedList(), // 有序列表
        new TodoItem(),
        new TodoList(),
        new Fullscreen(),
        new Preview(),
        new CodeBlock(),
        new TextColor()
      ],
      formRules: {
        name: [
          {
            required: true,
            message: '案件名称不能为空',
            trigger: 'blur'
          }
        ],
        caseType: [
          {
            required: true,
            message: '案件类型不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.loadEditCase()
  },
  methods: {
    loadEditCase () {
      getDictionaryByTypeCode({code:'CASETYPE',status:0}).then(res => {
        this.caseData = res
      })
      getCaseId(this.CaseId).then(res => {
        console.log(res, '666')
        this.caseEditForm.caseId = res.CaseId
        this.caseEditForm.seqNo = res.SeqNo
        this.caseEditForm.name = res.Name
        this.caseEditForm.caseType = res.CaseType
        this.caseEditForm.resume = res.Resume
        this.caseEditForm.detail = res.Detail
      })
    },
    editSaveBtn () {
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        const CaseId = this.CaseId
        if (CaseId) {
          editCase(CaseId, this.caseEditForm).then(res => {
            console.log(res, '111')
            this.caseEditForm = res.data
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.loadEditCase()
          })
          this.$emit('editChanged', false)
        }
      })
    },
    closeBtn () {
      this.$emit('editChanged', false)
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
