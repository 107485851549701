<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>全部案件</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="form" :model="search" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input placeholder="请输入案件名称" v-model="search.keyword" class="input-with-select">
              <el-button slot="append" type="warning" icon="el-icon-search" @click="loadCase(1)"></el-button>
            </el-input>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" @click="toggle" plain>高级搜索</el-button>
          </el-col>
        </el-row>
        <el-row ref="searchForm" class="sf-search-more" v-show="isShow">
          <el-col :span="6">
            <el-form-item label="案件名称" label-width="80px">
              <el-input placeholder="" v-model="search.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="案件编号" label-width="80px">
              <el-input placeholder="" v-model="search.seqNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="案件类型" label-width="80px">
              <el-select placeholder="案件类型" v-model="search.caseType">
                <el-option v-for="item in caseData" :key="item.Name" :label="item.Name"
                           :value="item.Name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="处理进度" label-width="80px">
              <el-select placeholder="处理进度" v-model="search.status">
                <el-option v-for="item in caseStatusList" :key="item.key" :label="item.name"
                           :value="item.key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="text-align: right;">
            <el-form-item>
              <el-button type="primary" @click="loadCase(1)">搜索</el-button>
              <el-button type="primary" plain @click="resetForm()">清空</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table :data="tableData" border v-loading="loading" :height="tableHeight" style="width: 100%; margin: 20px 0" v-if="showTable">
        <el-table-column prop="CaseId" align="center" v-model="tableData.CaseId" label="序号" width="60">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="SeqNo" align="center" v-model="tableData.SeqNo" label="案件编号" width="120">
        </el-table-column>
        <el-table-column prop="Name" align="center" label="案件名称" v-model="tableData.Name">
        </el-table-column>
        <el-table-column prop="CaseType" align="center" label="案件类型" v-model="tableData.CaseType">
        </el-table-column>
        <el-table-column prop="Resume" align="center" label="案件简述" v-model="tableData.Resume">
        </el-table-column>
        <el-table-column prop="Status" align="center" label="处理进度">
          <template slot-scope="scope">
            <div v-if="scope.row.Status === 0">待办</div>
            <div v-else-if="scope.row.Status === 1">已办</div>
          </template>
        </el-table-column>
        <el-table-column prop="LastTime" align="center" v-model="tableData.LastTime" label="持续时长">
        </el-table-column>
        <el-table-column prop="CreateTime" align="center" v-model="tableData.CreateTime" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="ajOperate" align="center" label="操作" width="180">
          <div class="icon-box" slot-scope="scope">
            <a @click="handle(scope.row)" class="btn-view"><i class="el-icon-view"></i><span>{{scope.row.Status === 0 ? '办理' : '查看'}}</span></a>
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        background
        layout="total, sizes, prev, pager, next"
        :disabled="loading"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
    </el-card>

  </div>
</template>

<script>
import { getCase, deleteCase } from '@/api/case'
// import { wpsGenarate } from '@/api/word'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'Cased',
  components: {},
  props: {},
  data () {
    return {
      caseStatusList: [
        { key: -1, name: '全部' },
        { key: 0, name: '待办' },
        { key: 1, name: '已办' }
      ],
      caseData: [],
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: '',
        name: '',
        seqNo: '',
        caseType: '',
        status: -1
      },
      totalPages: 0,
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      tableData: [],
      tableData2: [],
      showTable: true,
      input1: '',
      isShow: false,
      dialogFormVisible: false,
      tableHeight: 500,
      refresh1: true
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadCase(1)
  },
  mounted () {
    this.$nextTick(function () {
      this.tableHeight = window.innerHeight - this.$refs.tableRef.$el.getBoundingClientRect().top - 137
      const that = this
      window.onresize = function () {
        that.tableHeight = window.innerHeight - that.$refs.tableRef.$el.getBoundingClientRect().top - 137
      }
    })
  },
  methods: {
    resetForm () {
      this.search.name = null
      this.search.seqNo = null
      this.search.caseType = null
    },
    refresh () {
      this.refresh1 = false
      this.$nextTick(() => {
        this.refresh1 = true
      })
    },
    loadCase (page, tf = false) {
      // 展示加载中 loading
      this.loading = true
      if(page){
        this.search.page = page
      }
      getCase(this.search).then(res => {
        console.log('getCaseres', res)
        this.tableData = []
        this.tableData2 = []
        if (res.data) {
          this.pageInfo.currentPage = res.currentPage
          this.pageInfo.pageSize = res.pageSize
          this.pageInfo.total = res.total
          this.tableData = res.data
          this.tableData2 = res.data
          console.log('this.tableData', this.tableData)
          // 新增完毕
          if (tf) {
            this.search.page = res.totalPages
            this.front()
          }
        }
        // 关闭加载中 loading
        this.loading = false
      })
    },
    front () {
      getCase(this.search).then(res => {
        const data = res.data[res.data.length - 1]
        for (let i = 1; i < this.tableData2.length; i++) {
          this.tableData2[this.tableData2.length - i] = this.tableData2[this.tableData2.length - i - 1]
        }
        this.tableData2[0] = data
        this.tableData = this.tableData2
        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      })
    },
    // 高级搜索框开关
    toggle () {
      // wpsGenarate({ fileName: '1.docx', userId: 123 })
      getDictionaryByTypeCode({code:'CASETYPE',status:0}).then(res => {
        this.caseData = res
      })
      this.isShow = !this.isShow
    },
    handle (row) {
      this.$router.push({
        path: '/case/detail',
        query: {
          CaseId: row.CaseId
        }
      })
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadCase() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadCase()
    },
    getIndex ($index) {
      return (this.pageInfo.currentPage - 1) * this.pageInfo.pageSize + $index + 1
    }
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
