<template>
  <el-form ref="editForm" :model="editForm" label-width="80px" size="mini">
    <el-form-item label="印章编号">
      <el-input v-model="editForm.id"></el-input>
    </el-form-item>
    <el-form-item label="印章名称">
      <el-input v-model="editForm.name"></el-input>
    </el-form-item>
    <el-form-item label="印章照片">
      <el-upload
        action="#"
        list-type="picture-card"
        :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="editForm.photoPath" alt="">
      </el-dialog>
    </el-form-item>
    <el-button type="primary" @click="sealEditSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import { editSeal, getSealId } from '@/api/seal'

export default {
  name: 'increase',
  components: {},
  props: {
    dialogEditVisible: {
      type: Boolean,
      default: true // 默认值
    },
    index: {
      type: Number,
      default: 123
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      editForm: {
        name: '',
        id: 0,
        photoPath: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.loadEditSeal()
  },
  methods: {
    loadEditSeal () {
      const ids = this.tableData.map((item) => item.SealId)
      getSealId(ids[this.index]).then(res => {
        this.editForm.id = res.SealId
        this.editForm.name = res.Name
        this.editForm.photoPath = res.PhotoPath
      })
    },
    sealEditSaveBtn () {
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        // 找到数据接口
        // 封装请求方法
        // 请求提交表单
        // 如果是修改文章，则执行修改操作
        const ids = this.tableData.map((item) => item.SealId)
        const editId = ids[this.index]
        if (editId) {
          editSeal(editId, this.editForm).then(res => {
            this.editForm = res.data
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
          this.$emit('editChanged', false)
          this.loadEditSeal()
        }
      })
    },
    closeBtn () {
      this.$emit('editChanged', false)
    },
    handleRemove (file) {
      console.log(file)
    },
    handlePictureCardPreview (file) {
      this.photoPath = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    }
  }
}
</script>

<style scoped>
</style>
