<template>
  <el-form ref="addForm" :model="increaseForm" label-width="80px" size="mini">

    <el-form-item label="水印内容" prop="content">
      <el-input v-model="increaseForm.content"></el-input>
    </el-form-item>

    <el-form-item label="水印描述" prop="describe">
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="increaseForm.describe"
        maxlength="130"
        show-word-limit
      >
      </el-input>
    </el-form-item>
    <el-button type="primary" @click="increaseSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import { addWaterMark } from '@/api/waterMark'

export default {
  name: 'increase',
  props: ['dialogFormVisible'],
  data () {
    return {
      increaseForm: {
        content: '',
        describe: ''
      }
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    increaseSaveBtn () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const t = this
          addWaterMark(this.increaseForm).then((res) => {
            if (res.success) {
              t.$message({
                message: '添加成功',
                type: 'success'
              })
            } else {
              t.$message({
                message: '添加成功',
                type: 'success'
              })
            }
          }).catch(res => {
            console.log(res)
          })
          this.$emit('dialogChanged', false)
          this.$refs.addForm.resetFields()
        }
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
