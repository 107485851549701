<template>
  <el-form ref="peopleForm" :model="dictionaryForm" label-width="100px" size="mini" :rules="formRules">
    <el-form-item label="上级类型" prop="parentId">
      <el-select placeholder="上级类型" v-model="dictionaryForm.parentId" style="float:left;">
        <el-option v-for="item in fristType" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="类型名称" prop="name">
      <el-input v-model="dictionaryForm.name"></el-input>
    </el-form-item>
    <el-form-item label="类型编码" prop="typeCode">
      <el-input v-model="dictionaryForm.typeCode"></el-input>
    </el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>
  </el-form>
</template>

<script>
import { addSysDictionaryType } from '@/api/dictionary'
export default {
  name: 'dictionary-increase',
  props: {
    dictId: {
      type: String,
      default: '0'
    },
    fristType: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dictionaryForm: {
        parentId: '',
        name: '',
        typeCode: '',
        sort: 0
      },
      formRules: {
        // parentId: [
        //   {
        //     required: true,
        //     message: '不能为空',
        //     trigger: 'blur'
        //   }
        // ],
      }
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    initialization() {
      this.dictionaryForm.parentId=this.dictId
    },
    onSubmit () {
      this.$refs.peopleForm.validate((valid) => {
        if (valid) {
          const t = this
          addSysDictionaryType(this.dictionaryForm).then((res) => {
            t.close('peopleForm')
            t.$message({
              message: '添加成功',
              type: 'success'
            })
          }).catch(res => {
            t.$message({
              message: res.message,
              type: 'warning'
            })
          })
        }
      })
    },
    close (refName) {
      this.$emit('handleClick1', false);
      this.$refs[refName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
