<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文件模板管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="form" :model="search" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="10">
            <div>
              <el-input placeholder="请输入模板名称" v-model="search.keyword" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="loadTemplates()"></el-button>
              </el-input>
            </div>
          </el-col>
          <!--/数据表格筛选-->
          <el-col :span="14" class="text-align-r">
            <el-button-group>
              <el-button type="primary" @click="add">新增</el-button>
              <el-button type="primary" @click="dialogEditFormVisible = true">编辑</el-button>
              <el-button @click="deleteBtn(null)" :index="this.index" type="primary">删除</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </el-form>

      <!--文件模版列表内容-->
      <div class="sf-template" ref="templateData" v-loading="loading" prop="tableData">
        <ul>
          <li v-for="(item,index) in tableData" :key="index" :class="{changeStyle:changeSelectStyle == index}"
              @click="changeStyle(index)">
            <el-link>{{ item.Name }}</el-link>
          </li>
        </ul>
      </div>
      <!--/文件模版列表内容-->

      <!--分页条-->
      <el-pagination class="pagination" @size-change="changePageSize" @current-change="changeCurrentPage"
                     background layout="total, sizes, prev, pager, next" :disabled="loading"
                     :current-page="pageInfo.currentPage"
                     :page-size="pageInfo.pageSize" :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
    </el-card>

    <el-dialog class="increaseForm pub_dialog" v-if="dialogFormVisible" title="新增文件模板" append-to-body
               :visible.sync="dialogFormVisible" :close-on-click-modal="false" top="0px" fullscreen=true>
<!--      <div id="wps" align="right" class="scenes-div-wps"></div>-->
     <increase :dialogFormVisible="dialogFormVisible" @dialogChanged="IncreaseDataChange($event)"/>
    </el-dialog>

    <el-dialog class="increaseForm" v-if="dialogEditFormVisible" title="编辑模板" append-to-body
               :visible.sync="dialogEditFormVisible" :close-on-click-modal="false" top="0px" fullscreen=true>
      <edit :dialogEditFormVisible="dialogEditFormVisible" :tableData="this.tableData" :index="this.index" @editChanged="editDataChange($event)"/>
    </el-dialog>
    <div id="wps" align="right" class="scenes-div-wps" style="position: absolute;top: 0"></div>
  </div>
</template>

<script>
import {
  queryTemplate, deleteTemplate
} from '@/api/template'
import Increase from './components/increase'
import Edit from './components/edit'

// let {WPSFrame} = require('./assets/wps_general')
// let require( './assets/wps_general')
// require( './assets/wps-tools')
import {init,openDocumentFromSever} from './assets/wps-tools'
export default {
  name: 'Template',
  components: {
    increase: Increase,
    Edit
  },
  props: {},
  data () {
    return {
      changeSelectStyle: '',
      templateType: [],
      TemplateId: 1,
      index: 1,
      input1: '',
      input2: '',
      input3: '',
      select: '',
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: ''
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      // 绑定数据
      tableData: [],
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      loading: true // 表单数据加载中 loading
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadTemplates()
  },
  mounted () {
    // var wpsFrame =  new WPSFrame();
    // openDocumentFromSever(window.wpsFrame)
    // console.log(wpsFrame)
    // init(window.wpsFrame,true)
    // checkEnvironment()
  },
  methods: {
    add(){
      this.dialogFormVisible = true;
      init(false)
      // openDocumentFromSever(window.wpsFrame)
    },
    // 选中样式切换
    changeStyle: function (index) {
      this.changeSelectStyle = index
      this.index = index
    },
    deleteBtn (row) {
      let ids = []
      if (row === null) {
        const tableData = this.tableData
        if (tableData.length === 0) {
          this.$message({
            message: '请选择一条数据进行删除！',
            type: 'warning'
          })
          return
        }
        ids = tableData.map((item) => item.TemplateId)
      } else {
        ids.push(this.index.TemplateId)
      }
      this.$confirm('此操作将永久删除该文件模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(ids, '6789')
        deleteTemplate(ids[this.index]).then((res) => {
          // 删除成功，更新当前页的文章数据列表
          this.loadTemplates(this.page)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    IncreaseDataChange (params) {
      this.dialogFormVisible = params
      this.loadTemplates(this.page)
    },
    editDataChange (params) {
      this.loadTemplates(this.page)
      this.dialogEditFormVisible = params
    },
    // 加载默认用户数据
    loadTemplates () {
      // 展示加载中 loading
      this.loading = true
      queryTemplate(this.search).then(res => {
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        this.tableData = res.data
        // 关闭加载中 loading
        this.loading = false
      })
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadTemplates() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadTemplates()
    },
    loadArticles (page = 1) {
    },
    handle (row, column, event, cell) {
      if (column.property !== 'ajOperate') {
        this.$router.push({
          path: '/template/detail'
        })
      }
    }
  }
}
</script>

<style>
@import "./assets/wps.css";
/* .scenes-div-wps {
  width:800px;
  height:500px;
} */
</style>
<style>

.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
}
/* .el-dialog {
        margin: 0 auto !important;
        height: 100%;
        overflow: hidden;
} */
    /* .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 10px 0 10px 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        } */

.changeStyle a {
  color: #409EFF !important;
}

.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  height:100%;
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}

.sf-template {
  display: flex;
  border: #dcdfe6 1px solid;
  margin: 20px 0;
  padding: 10px;
  overflow-y: scroll;
}

.sf-template ul {
  width: 100%;
  height: 330px;
  padding: 0;
  margin: 0;
}

.sf-template ul li {
  box-sizing: border-box;
  float: left;
  display: flex;
  width: calc(19.9% - 28px);
  height: 270px;
  margin: 14px;
  border: #e7e9f0 1px solid;
  box-shadow: 3px 3px 3px #eee;
  background-color: #f4f8ff;
}

.sf-template ul li a {
  width: 100%;
  padding: 20px;
  font-size: 24px;
  text-align: center;
  line-height: 38px;
  font-family: 宋体;
  font-weight: bold;
}

@media (min-width: 1920px) {
  .sf-template ul li {
    width: calc(16.6% - 28px);
    height: 310px;
  }

  .sf-template ul {
    height: 490px;
  }
}

.el-select .el-input {
  width: 150px;
}

.el-input-group__append {
  background-color: #5486e5 !important;
  color: #fff !important;
  font-size: 22px;
  padding: 0 13px !important;
}

.el-input-group__append:hover {
  background-color: #71a0fa !important;
}
</style>
