<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">
      <!--数据表格筛选-->
      <el-form ref="form" :model="search" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input
              placeholder="请输入用户名"
              v-model="search.keyword"
              class="input-with-select"
            >
              <el-button
                slot="append"
                type="warning"
                icon="el-icon-search"
                @click="loadUserInfos()"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="17" class="text-align-r">
            <el-button
              type="warning"
              icon="el-icon-plus"
              @click="dialogFormVisible = true"
            >添加用户
            </el-button
            >
            <el-dialog
              class="increaseForm"
              title="新增用户"
              append-to-body
              :visible.sync="dialogFormVisible"
              :close-on-click-modal="false"
            >
              <increase @increaseDataChange="increaseDataChange($event)"/>
            </el-dialog>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table ref="tableRef" :data="tableData" border v-loading="loading" style="width: 100%; margin: 20px 0;">
        <el-table-column
          prop="UserId"
          v-model="tableData.UserId"
          align="center"
          label="序号"
          width="60"
        >
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="UserName"
          v-model="tableData.UserName"
          align="center"
          label="用户名"
        ></el-table-column>
        <el-table-column
          prop="Name"
          v-model="tableData.Name"
          align="center"
          label="姓名"
        ></el-table-column>

        <el-table-column
          prop="Sex"
          v-model="tableData.Sex"
          align="center"
          label="性别"
        ></el-table-column>
        <el-table-column
          prop="Phone"
          v-model="tableData.Phone"
          align="center"
          label="手机号码"
        ></el-table-column>
        <el-table-column
          prop="uRole"
          v-model="tableData.uRole"
          align="center"
          label="角色"
        ></el-table-column>

        <el-table-column
          prop="CreateTime"
          v-model="tableData.CreateTime"
          align="center"
          label="创建时间"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="ModifyTime"
          v-model="tableData.ModifyTime"
          align="center"
          label="更新时间"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="uOperate"
          align="center"
          label="操作"
          width="200"
        >
          <div class="icon-box" slot-scope="scope">
            <a @click="handleCheck(scope.row.UserId)" class="btn-view"
            ><i class="el-icon-view"></i>查看</a
            >
            <a @click="handleUpdate(scope.row.UserId)" class="btn-edit"
            ><i class="el-icon-edit"></i>编辑</a>
            <a @click="changeStatus(scope.row.UserId,!scope.row.IsEnable)" class="btn-edit"
            ><i class="el-icon-edit"></i>{{ scope.row.IsEnable ? "禁用" : "启用" }}</a>

            <a v-if="scope.row.UserId!==1" @click="restUserInfoPassword(scope.row.UserId)" class="btn-edit"
            ><i class="el-icon-edit"></i>重置密码</a>
            <a
              href="javascript:"
              @click="deleteUserInfo(scope.row)"
              class="btn-delete"
            ><i class="el-icon-delete"></i>删除</a>
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        background
        :disabled="loading"
        layout="total, sizes, prev, pager, next"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
      >
      </el-pagination>
      <!--/分页条-->
    </el-card>
    <el-dialog
      class="increaseForm"
      title="查看用户"
      v-if="dialogCheckFormVisible"
      append-to-body
      :visible.sync="dialogCheckFormVisible"
      :close-on-click-modal="false"
    >
      <check :dialogCheckFormVisible="dialogCheckFormVisible" :UserId="tableData.UserId"
             @checkChanged="checkDataChange($event)"/>
    </el-dialog>
    <el-dialog
      class="increaseForm"
      title="编辑用户"
      v-if="dialogEditFormVisible"
      append-to-body
      :visible.sync="dialogEditFormVisible"
      :close-on-click-modal="false"
    >
      <edit :dialogEditFormVisible="dialogEditFormVisible" :UserId="tableData.UserId"
            @editChanged="editDataChange($event)"/>
    </el-dialog>
  </div>
</template>
<script>
import Increase from './components/users-increase'
import Edit from './components/users-edit'
import Check from './components/users-check'
import { queryUserInfo, deleteUserInfo, resetPassword, editStatus } from '@/api/userInfo'

export default {
  name: 'Users',
  components: {
    increase: Increase,
    Edit,
    Check
  },
  props: {},
  data () {
    return {
      form: {},
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: ''
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      // 绑定数据
      tableData: [{
        UserId: 1,
        UserName: '',
        Name: '',
        Sex: '',
        Phone: '',
        CreateTime: '',
        ModifyTime: '',
        uRole: '',
        IsEnable: ''
      }],
      dialogFormVisible: false,
      loading: true, // 表单数据加载中 loading
      dialogEditFormVisible: false,
      tableHeight: 500,
      dialogCheckFormVisible: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadUserInfos(1)
  },
  mounted () {
  },
  methods: {
    // 加载默认用户数据
    loadUserInfos (page = 1) {
      // 展示加载中 loading
      this.loading = true
      queryUserInfo(this.search).then((res) => {
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        this.tableData = res.data
        // 关闭加载中 loading
        this.loading = false
      })
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadUserInfos() // 点击每页显示的条数时，显示第一页
    },
    getIndex ($index) {
      return (this.pageInfo.currentPage - 1) * this.pageInfo.pageSize + $index + 1
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadUserInfos()
    },
    // 查看事件
    handleCheck (UserId) {
      this.tableData.UserId = UserId
      this.dialogCheckFormVisible = true
    },
    // 编辑事件
    handleUpdate (UserId) {
      this.tableData.UserId = UserId
      this.dialogEditFormVisible = true
    },
    increaseDataChange (params) {
      console.log('increaseDataChange',params)
      this.loadUserInfos(this.page)
      this.dialogFormVisible = params
    },
    checkDataChange (params) {
      this.loadUserInfos(this.page)
      this.dialogCheckFormVisible = params
    },
    editDataChange (params) {
      this.loadUserInfos(this.page)
      this.dialogEditFormVisible = params
    },
    deleteUserInfo (row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUserInfo(row.UserId).then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.loadUserInfos(this.page)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 改变用户状态
    changeStatus (userId, status) {
      this.$confirm(`此操作将${status === true ? '启用' : '禁用'}该用户登录, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          editStatus({ id: userId, status: status }).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.loadUserInfos(this.page)
          })
            .catch((er) => {
              console.log(er)
            })
        })
    },
    // 重置密码
    restUserInfoPassword (userId) {
      this.$confirm('此操作将重置该用户登录密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          resetPassword(userId).then((res) => {
            this.$message({
              type: 'success',
              message: '重置密码成功,新密码为：' + res.data
            })
          })
            .catch((er) => {
              console.log(er)
            })
        })
    }
  }
}
</script>

<style>
.el-table__body-wrapper {
  height: calc(100vh - 380px);
  overflow: auto !important;
}

.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
