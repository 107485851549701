<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>水印管理</el-breadcrumb-item> -->
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="form" :model="search" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input placeholder="请输入水印内容" v-model="search.keyword" class="input-with-select">
              <el-button slot="append" type="warning" icon="el-icon-search" @click="loadWaterMark()"></el-button>
            </el-input>
          </el-col>
          <el-col :span="17" class="text-align-r">
            <el-button type="warning" icon="el-icon-plus" @click="dialogFormVisible = true">添加水印</el-button>
            <el-dialog class="increaseForm" title="新增水印" append-to-body :visible.sync="dialogFormVisible"
                       :close-on-click-modal="false">
              <increase :dialogFormVisible="dialogFormVisible" @dialogChanged="IncreaseDataChange($event)" />
            </el-dialog>
          </el-col>
        </el-row>

      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table :data="tableData" v-loading="loading" border style="width: 100%; margin: 20px 0">
        <el-table-column prop="WaterMarkId" v-model="tableData.WaterMarkId" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="Content" v-model="tableData.Content" align="center" label="水印内容">
        </el-table-column>
        <el-table-column prop="Describe" v-model="tableData.Describe" align="center" label="水印描述">
        </el-table-column>
        <el-table-column prop="CreateTime" v-model="tableData.CreateTime" align="center" label="创建时间" width="160">
        </el-table-column>
        <el-table-column prop="ModifyTime" v-model="tableData.ModifyTime" align="center" label="更新时间" width="160">
        </el-table-column>
        <el-table-column prop="syOperate" align="center" label="操作" width="130">
          <div class="icon-box" slot-scope="scope">
            <a @click="handleUpdate(scope.row.WaterMarkId)" class="btn-edit"><i class="el-icon-edit"></i>编辑</a>
            <a @click="deleteBtn(scope.row)" class="btn-delete"><i class="el-icon-delete"></i>删除</a>
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        background
        layout="total, sizes, prev, pager, next"
        :disabled="loading"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->

      <el-dialog class="increaseForm" v-if="dialogEditFormVisible" title="编辑水印" append-to-body
                 :visible.sync="dialogEditFormVisible" :close-on-click-modal="false">
        <edit :dialogEditFormVisible="dialogEditFormVisible" :WaterMarkId="tableData.WaterMarkId" @editChanged="editDataChange($event)"/>
      </el-dialog>
    </el-card>

  </div>
</template>

<script>
import Increase from './components/increase'
import Edit from './components/edit'
import { queryWaterMark, deleteWaterMark } from '@/api/waterMark'

export default {
  name: 'Settings',
  components: {
    increase: Increase,
    Edit
  },
  props: {},
  data () {
    return {
      form: {},
      tableData: [{
        WaterMarkId: '1',
        Content: '水印内容',
        Describe: '水印描述',
        CreateTime: '2016-05-02',
        ModifyTime: '2016-05-02',
        syOperate: '操作'
      }],
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: ''
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      input1: '',
      loading: true,
      dialogFormVisible: false,
      dialogEditFormVisible: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadWaterMark(1)
  },
  mounted () {
  },
  methods: {
    loadWaterMark (page = 1) {
      // 展示加载中 loading
      this.loading = true
      queryWaterMark(this.search).then(res => {
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        this.tableData = res.data
        // 关闭加载中 loading
        this.loading = false
      })
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadWaterMark() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadWaterMark()
    },
    getIndex ($index) {
      return (this.pageInfo.currentPage - 1) * this.pageInfo.pageSize + $index + 1
    },
    editDataChange (params) {
      this.loadWaterMark(this.page)
      this.dialogEditFormVisible = params
    },
    // 编辑事件
    handleUpdate (WaterMarkId) {
      this.tableData.WaterMarkId = WaterMarkId
      this.dialogEditFormVisible = true
    },
    deleteBtn (row) {
      this.$confirm('此操作将永久删除该水印, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteWaterMark(row.WaterMarkId).then((res) => {
          // 删除成功，更新当前页的文章数据列表
          this.loadWaterMark(this.page)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    IncreaseDataChange (params) {
      this.dialogFormVisible = params
      this.loadWaterMark(this.page)
    }
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}

</style>
