<template>
  <el-form ref="peopleForm" :model="dictionaryForm" label-width="100px" size="mini" :rules="formRules">
    <!-- <el-form-item label="字典类型序号" prop="dictTypeId">
      <el-input v-model="dictionaryForm.dictTypeId"></el-input>
    </el-form-item> -->
    <el-form-item label="字典名称" prop="dictName">
      <el-input v-model="dictionaryForm.dictName"></el-input>
    </el-form-item>
    <el-form-item label="字典内容" prop="dictContent">
      <el-input v-model="dictionaryForm.dictContent"></el-input>
    </el-form-item>
    <el-form-item label="状态" prop="status">
      <el-switch
        style="float:left;margin:5px 0 0 0;"
        v-model="dictionaryForm.status"
        active-color="#13ce66"
        inactive-color="#ff4949">
      </el-switch>
    </el-form-item>
    <el-form-item label="排序" prop="sort">
      <el-input v-model="dictionaryForm.sort"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input v-model="dictionaryForm.remark"></el-input>
    </el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>
  </el-form>
</template>

<script>
import { addSysDictionary } from '@/api/dictionary'
export default {
  name: 'dictionary-increase2',
  props: {
    dictId: {
      type: String,
      default: 0
    }
  },
  data () {
    return {
      dictionaryForm: {
        dictTypeId: '',
        dictName: '',
        dictContent: '',
        status: true,
        sort: '',
        remark: ''
      },
      formRules: {
        dictName: [
          {
            required: true,
            message: '字典名称不能为空',
            trigger: 'blur'
          }
        ],
        dictContent: [
          {
            required: true,
            message: '字典内容不能为空',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '排序不能为空',
            trigger: 'blur'
          }
        ],
      },
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    initialization(){
      this.dictionaryForm.dictTypeId=this.dictId-0
    },
    onSubmit () {
      this.$refs.peopleForm.validate((valid) => {
        if (valid) {
          const t = this
          addSysDictionary(this.dictionaryForm).then((res) => {
            console.log("addSysDictionary",res)
            t.close('peopleForm')
            if (res.success) {
              console.log("res.success1",res.success)
              t.$message({
                message: res.message,
                type: 'success'
              })
              
            } else {
              console.log("res.success2",res.success)
              t.$message({
                message: '添加成功',
                type: 'success'
              })
            }
          }).catch(res => {
            console.log(res)
          })
        }
      })
    },
    close (refName) {
      this.$emit('handleClick4', false);
      this.$refs[refName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
